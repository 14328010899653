import { render, staticRenderFns } from "./index.vue?vue&type=template&id=16e1007b"
import script from "@/views/Admin/Reports/V2/Dependencies/ListData/indexLogic.ts?vue&type=script&lang=ts&external"
export * from "@/views/Admin/Reports/V2/Dependencies/ListData/indexLogic.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports